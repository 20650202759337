$light-blue: rgba(0, 0, 102, 0.05);
$dark-grey: rgba(0, 0, 0, 0.7);
$light-grey: rgba(0, 0, 0, 0.2);
$lightest-grey: rgba(0, 0, 0, 0.1);
$grey: #949494;
$light-grey: rgba(0, 0, 0, 0.5);
$light-grey-shadow: rgba(0, 0, 0, 0.08);
$theme-blue: #006;
$white-color: #fff;
$green-color: #4a9e42;
$light-blue-sky: rgba(74, 158, 66, 0.05);
$light-blue-dim: rgba(0, 0, 0, 0.03);
$light-green-color: rgba(74, 158, 66, 0.1);
$musturd-color: rgba(241, 148, 8, 1);

button,
input,
optgroup,
select,
textarea,
.MuiInputLabel-root,
.MuiButton-root,
.MuiTypography-root,
.MuiFormHelperText-root,
.MuiTableCell-root,
.MuiMenuItem-root {
  font-family: 'Montserrat', sans-serif !important;
}

//-------------webkit ---------------------

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

//------------- product details--------------

.product-container {
  border-radius: 5px;
  box-shadow: 0px 2px 8px 1px $light-grey-shadow;
  padding: 20px 25px;

  .product-child-container {
    padding: 20px 25px;
    background-color: $light-blue;
    border-radius: 5px;

    .product-heading :nth-child(1) {
      font-weight: 500;
      color: $green-color;
      font-size: 10px;
    }
    .product-details {
      display: flex;
      justify-content: space-between;
      // flex-direction: row;
      padding: 0px;

      & > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        width: 30%;

        & > :nth-child(1) {
          padding: 5px;
          margin: auto 0;
          width: 100px;
          height: 40px;
          object-fit: contain;
          background-color: $white-color;
          border-radius: 5px;
        }
        & > :nth-child(2) {
          padding: 16px;
          & > :nth-child(1) {
            font-size: 16px;
            font-weight: 600;
            color: black;
          }
          & > :nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: $dark-grey;
          }
          & > :nth-child(3) {
            font-size: 12px;
            font-weight: 400;
            color: $green-color;
            display: none;
          }
        }
      }
      & > :nth-child(2) {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 45%;

        & > :nth-child(1),
        :nth-child(2),
        :nth-child(3) {
          & > :nth-child(1) {
            color: $grey;
          }
          & > :nth-child(2) {
            color: $theme-blue;
          }
        }
      }
      & > :nth-child(3) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;

        // & > :nth-child(2) {
        //   display: none;
        // }
      }
    }
  }
}

// ----------- keycoverage details ------------------

.keycoverage-details-container {
  margin: 40px 0px;

  .keycoverage-details {
    padding: 20px 25px;
    background-color: $light-blue-dim;
    border-radius: 12px;

    ul {
      margin-bottom: 0;
      list-style: none;

      & > :nth-child(1) {
        margin-bottom: 15px;
      }
      & > :nth-child(2) {
        margin-bottom: 15px;
      }

      li::before {
        content: '\2022';
        color: $theme-blue;
        font-weight: 900;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        vertical-align: top;
      }
      li > :nth-child(1) {
        color: $theme-blue;
        font-weight: 600;
        font-size: 14px;
        display: inline-block;
      }
      li > :nth-child(2) {
        color: $light-grey;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

//----- coverage-details-------------

.coverage-details-container {
  padding: 20px 25px;
  background-color: $light-blue-sky;
  border-radius: 5px;
  margin-bottom: 40px;

  .coverage-details {
    display: flex;
    justify-content: start;
    column-gap: 30px;
    row-gap: 10px;
    flex-wrap: wrap;

    .coverage-points {
      display: flex;
      justify-content: center;
      gap: 10px;

      img {
        margin-top: 3px;
        height: 16px;
        width: 16px;
      }

      & > :nth-child(2) {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

//-------------coverage accordian -------------

.coverage-accordian {
  display: none;
  margin-bottom: 40px;
  .css-1yuhvjn {
    margin-top: 0;
  }
  .coverage-accordian-details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 10px;

    .coverage-points {
      display: flex;

      img {
        margin-top: 3px;
        height: 16px;
        width: 16px;
      }
  
      & > :nth-child(2) {
        margin-left: 14px;
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

//----- uncoverage-details-------------

.uncoverage-details-container {
  padding: 20px 25px;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-bottom: 40px;
  box-shadow: 0px 2px 8px 1px $light-grey-shadow;

  .uncoverage-details {
    display: flex;
    justify-content: start;
    column-gap: 30px;
    row-gap: 10px;
    flex-wrap: wrap;

    .uncoverage-points {
      display: flex;
      justify-content: center;
      gap: 10px;

      img {
        margin-top: 3px;
        height: 16px;
        width: 16px;
      }

      & > :nth-child(2) {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

//--------uncoverage accordian ----------------

.uncoverage-accordian {
  display: none;
  margin-bottom: 40px;

  & > :nth-child(1) {
    margin: 0px;
  }
  .css-164r41r {
    margin-top: 0px;
  }

  .uncoverage-accordian-details {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 10px;

    .uncoverage-points {
      display: flex;

      img {
        margin-top: 3px;
        height: 16px;
        width: 16px;
      }
  
      & > :nth-child(2) {
        margin-left: 14px;
        display: inline-block;
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

// --------links and documents ------------------

.links-doc-container {
  margin-bottom: 40px;
  .links-doc {
    display: flex;
    margin-top: 15px;
    .link {
      font-size: 12px;
      margin-right: 50px;
      font-weight: 600;
    }
  }
}

//------------otp form -------------------------

.otp-fields-container {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;

  .otp-input-fields {
    margin: '10px 20px';
    height: '50px';
    width: '50px';
    border: '1px solid lightgrey';
    border-radius: '10px';
  }
}

//--------------otp  drwaer ------------------
.otp-drawer {
  display: none;
}

//-----------Payment Status Pages----------------

.payment_status_container {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .payment_success_logo {
    height: 60px;
    width: 60px;
  }

  .payment_fail_logo {
    height: 50px;
    width: 50px;
  }

  .payment_status_description {
    text-align: center;
    opacity: 0.7;
    font-size: 12px;
  }

  .payment_details {
    width: 100%;
    padding: 10px 20px;

    .payment_details_card {
      display: flex;
      justify-content: space-between;
      border: none;
      box-shadow: none;

      & > :nth-child(1),
      :nth-child(2) {
        font-size: 12px;
        opacity: 0.7;
      }
    }
  }

  .payment_action_button {
    width: 200px;
    color: #fff;
    border-radius: 20px;
    background-color: #02006b;

    &:hover {
      background-color: #02006b;
    }
  }
}

.mobile_payment_status_container {
  display: none;
}

.mobile_otp_error_container {
  display: none;
}

.otp_error_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 20px;
  margin: auto;
  gap: 15px;

  & > :nth-child(2) {
    color: #000066;
    font-weight: bold;
    font-size: 18px;
  }
}

//---------media screens -------------------------

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .banner-img {
    height: 77.4px  !important;
    width:  35% !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .banner-img {
    height: 77.4px  !important;
    width:  35% !important;
  }
}

@media screen and (min-width: 1440px) {
  .banner-img{
    height: 90px !important;
    width: 30% !important;
  }
}

@media screen and (min-width: 2560px) {
  .banner-img {
    height: 175px !important;
    width: 30% !important;
  }
}

@media screen and (max-width: 1199px) {
  .product-container {
    padding: 18px 18px;

    .product-child-container {
      .product-details {
        & > :nth-child(1) {
          & > :nth-child(1) {
            padding: 15px 18px;
          }
          & > :nth-child(2) {
            & > :nth-child(1) {
              font-size: 14px;
            }
            & > :nth-child(2) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .mobile_payment_status_container {
    display: none;
  }

  .mobile_otp_error_container {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .product-container {
    padding: 18px 18px;

    .product-child-container {
      .product-details {
        & > :nth-child(1) {
          & > :nth-child(1) {
            padding: 15px 15px;
          }
          & > :nth-child(2) {
            & > :nth-child(1) {
              font-size: 13px;
            }
            & > :nth-child(2) {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  .mobile_payment_status_container {
    display: none;
  }

  .mobile_otp_error_container {
    display: none;
  }
}

@media screen and (max-width: 899px) {
  .product-container {
    border-radius: 5px;
    box-shadow: 0px 2px 8px 1px $light-grey-shadow;
    padding: 0px;

    .product-child-container {
      padding: 0px;

      .product-heading :nth-child(1) {
        display: none;
        font-weight: 500;
        color: $green-color;
      }
      .product-details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0px;
        width: 100%;

        & > :nth-child(1) {
          display: flex;
          justify-content: space-around;
          width: 50%;

          & > :nth-child(2) {
            padding: 16px;
            & > :nth-child(1) {
              font-size: 16px;
              font-weight: 600;
              color: black;
            }
            & > :nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              color: $dark-grey;
            }
            & > :nth-child(3) {
              font-size: 12px;
              display: block;
            }
          }
        }
        & > :nth-child(2) {
          width: 50%;
        }
        & > :nth-child(3) {
          width: 100%;
          background-color: $white-color;
          padding: 25px 20px;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }

  .mobile_payment_status_container {
    display: none;
  }

  .mobile_otp_error_container {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .product-container {
    .product-child-container {
      .product-details {
        & > :nth-child(1) {
          & > :nth-child(2) {
            & > :nth-child(1) {
              font-size: 15px;
              font-weight: 600;
              color: black;
            }
            & > :nth-child(2) {
              font-size: 13px;
              font-weight: 400;
              color: $dark-grey;
            }
            & > :nth-child(3) {
              font-size: 11px;
              display: block;
            }
          }
        }
      }
    }
  }

  .mobile_payment_status_container {
    display: none;
  }

  .mobile_otp_error_container {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .product-container {
    border-radius: 12px;
    box-shadow: 0px 2px 8px 1px $light-grey-shadow;
    padding: 0px;

    .product-child-container {
      border-radius: 12px;
      padding: 0px;

      .product-heading :nth-child(1) {
        display: none;
        font-weight: 500;
        color: $green-color;
      }
      .product-details {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 0px;
        width: 100%;

        & > :nth-child(1) {
          border-radius: 12px 12px 0 0;
          display: flex;
          justify-content: space-between;
          width: 100%;
          background-color: $light-green-color;
          padding: 0px 30px;

          & > :nth-child(1) {
            padding: 5px;
            margin: auto 0;
            width: 100px;
            height: 40px;
            object-fit: contain;
          }

          & > :nth-child(2) {
            padding: 16px;
            justify-content: space-between;
            & > :nth-child(1) {
              font-size: 16px;
              font-weight: 600;
              color: black;
            }
            & > :nth-child(2) {
              font-size: 14px;
              font-weight: 400;
              color: $dark-grey;
            }
            & > :nth-child(3) {
              font-size: 12px;
              display: block;
              color: $musturd-color;
            }
          }
        }
        & > :nth-child(2) {
          width: 100%;
          justify-content: space-between;
          background-color: $white-color;
          padding: 20px 30px 0 30px;
        }
      }
    }
  }
  .keycoverage-details-container {
    margin: 30px 0px 20px 0px;

    .keycoverage-details {
      padding: 20px 10px 20px 0px;
      background-color: $light-blue-dim;
    }

    ul {
      li > :nth-child(2) {
        font-size: 12px;
      }
    }
  }

  .basic-info-otp,
  .basic-info-payment {
    border-radius: 10px;
    background-color: $light-blue-dim;
    padding: 20px 20px;
    width: 100%;
    & > :nth-child(1) {
      display: none;
    }
  }
  .coverage-accordian,
  .uncoverage-accordian {
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
      min-height: 0;
      padding: 20px 15px 5px 15px;
    }
    .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
      margin: 0px;
    }
    display: block;
    margin-bottom: 20px;
  }
  .coverage-details-container,
  .uncoverage-details-container {
    display: none;
  }

  .links-doc-container {
    border: 1px solid $lightest-grey;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 40px;
    .links-doc {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .link {
        margin-top: 10px;
        font-size: 13px;
        margin-right: 50px;
        font-weight: 600;
      }
    }
  }

  .mobile_payment_status_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;

    & > :nth-child(1) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 250px;
    }
    & > :nth-child(2) {
      width: 100%;
      position: relative;
      height: 60vh;

      & > :nth-child(1) {
        position: absolute;
        top: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;

        & > :nth-child(1) {
          width: 90%;
          border-radius: 20px;
          padding: 10px 20px;
          box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);

          .payment_details {
            width: 100%;
            padding: 10px 0px;

            .payment_details_card {
              display: flex;
              width: 100%;
              justify-content: space-between;
              border: none;
              box-shadow: none;

              & > :nth-child(1),
              :nth-child(2) {
                font-size: 12px;
                opacity: 0.7;
              }
            }
          }
        }

        & > :nth-child(2) {
          width: 90%;
          border-radius: 20px;
          padding: 10px 20px;
          box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);

          .payment_details {
            width: 100%;
            padding: 10px 0px;

            .payment_details_card {
              display: flex;
              width: 100%;
              justify-content: space-between;
              border: none;
              box-shadow: none;

              & > :nth-child(1),
              :nth-child(2) {
                font-size: 12px;
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
    .payment_action_button {
      position: relative;
      bottom: 20px;
      width: 90%;
      color: #fff;
      border-radius: 5px;
      padding: 10px 0;
      background-color: #02006b;
      &:hover {
        background-color: #02006b;
      }
    }
  }

  .mobile_otp_error_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80vh;
    padding: 0 20px;
    gap: 15px;

    & > :nth-child(2) {
      color: #000066;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .otp-drawer {
    display: block;
  }
}

@media screen and (max-width: 420px) {
  .product-container {
    .product-child-container {
      .product-details {
        & > :nth-child(1) {
          & > :nth-child(2) {
            & > :nth-child(1) {
              font-size: 14px;
            }
            & > :nth-child(2) {
              font-size: 12px;
            }
            & > :nth-child(3) {
              font-size: 10px;
            }
          }
        }
        & > :nth-child(2) {
          & > :nth-child(1),
          :nth-child(2),
          :nth-child(3) {
            & > :nth-child(1) {
              font-size: 12px;
            }
            & > :nth-child(2) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .keycoverage-details-container {
    margin: 30px 0px 20px 0px;

    .keycoverage-details {
      background-color: $light-blue-dim;
    }
  }
}
